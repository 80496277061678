<template>
<div>
  <div class="row">
    <div id="content" class="col-sm-12">
      <h2 class="title">Register Merchant Account</h2>
      <p>If you already have an account with us, please login at the <a @click="handleLogin">login page</a>.</p>
      <form class="form-horizontal account-register clearfix" @submit="checkForm">
        <fieldset>
          <legend>Account Type</legend>
          <div class="form-group required">
            <label class="col-sm-2 control-label" for="input-firstname">Account Type</label>
            <div class="col-sm-10">
              <select class="form-control" id="input-payment-country" name="country_id" v-model="form.account_type">
                <option value="-1">----- Account Type -----</option>
                <option value="1">Personal</option>
                <option value="2">Business</option>
              </select>
            </div>
          </div>
        </fieldset>
        <fieldset id="account">
          <legend>Merchant Personal Details</legend>
          <div class="form-group required">
            <label class="col-sm-2 control-label" for="input-firstname">First Name</label>
            <div class="col-sm-10">
              <input type="text" required v-model="form.first_name" name="firstname" value="" placeholder="First Name" id="input-firstname" class="form-control">
            </div>
          </div>
          <div class="form-group required">
            <label class="col-sm-2 control-label" for="input-middlename">Middle Name</label>
            <div class="col-sm-10">
              <input type="text" required v-model="form.middle_name" name="middlename" value="" placeholder="Middle Name" id="input-middlename" class="form-control">
            </div>
          </div>
          <div class="form-group required">
            <label class="col-sm-2 control-label" for="input-lastname">Last Name</label>
            <div class="col-sm-10">
              <input type="text" required v-model="form.last_name" name="lastname" value="" placeholder="Last Name" id="input-lastname" class="form-control">
            </div>
          </div>
          <div class="form-group required">
            <label class="col-sm-2 control-label" for="input-email">E-Mail</label>
            <div class="col-sm-10">
              <input type="email" required v-model="form.email" name="email" value="" placeholder="E-Mail" id="input-email" class="form-control">
            </div>
          </div>
          <div class="form-group required">
            <label class="col-sm-2 control-label" for="input-mobile">Mobile</label>
            <div class="col-sm-10">
              <VuePhoneNumberInput v-model="mobile" default-country-code="LK" no-example @update="setPhoneNo"/>
              <!-- <input type="number" required v-model="form.mobile" name="mobile" value="" placeholder="Mobile" id="input-mobile" class="form-control"> -->
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-2 control-label" for="input-mobile-optional">Fixed line number (optional)</label>
            <div class="col-sm-10">
              <input type="number" required v-model="form.fixed_line_number" name="mobile" value="" placeholder="Fixed line number" id="input-mobile-optional" class="form-control">
            </div>
          </div>
        </fieldset>
        <fieldset>
          <legend>Your Password</legend>
          <div class="form-group required">
            <label class="col-sm-2 control-label" for="password">Password</label>
            <div class="col-sm-10">
              <input type="password" required v-model="form.password" pattern="^\S{6,}$" onchange="this.setCustomValidity(this.validity.patternMismatch ? 'Must have at least 6 characters' : ''); if(this.checkValidity()) form.password_two.pattern = this.value;" name="password" placeholder="Password" id="password" class="form-control">
            </div>
          </div>
          <div class="form-group required">
            <label class="col-sm-2 control-label" for="password_two">Confirm Password</label>
            <div class="col-sm-10">
              <input type="password" required pattern="^\S{6,}$" onchange="this.setCustomValidity(this.validity.patternMismatch ? 'Please enter the same Password as above' : '');" placeholder="Verify Password" id="password_two" class="form-control">
            </div>
          </div>
        </fieldset>
        <div class="buttons">
          <b-row v-if="showReCapture === formIsValid">
            <b-col md="12">
              <vue-recaptcha class="pull-right" @verify="verifyEvent" sitekey="6Ldn8sYUAAAAAHeNiLb65O40B511b8PtFPA4fYLY" :loadRecaptchaScript="true"></vue-recaptcha>
            </b-col>
          </b-row>
          <b-row style="margin-top: 12px;">
            <b-col md="12">
              <div class="pull-right">I have read and agree to the <a href="#" class="agree"><b>Terms and Conditions</b></a>&nbsp;
                <input class="box-checkbox" required type="checkbox" name="agree" v-model="form.agree"> &nbsp;
                <input :disabled="disable" type="submit" value="Continue" class="btn btn-primary">
                <!-- <input type="submit" value="Continue1" class="btn btn-primary" @click="handleContinue()"> -->
              </div>
            </b-col>
          </b-row>
        </div>
      </form>
    </div>
  </div>
</div>
</template>
<script src="https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit" async defer>
</script>
<script>
import mixinAuth from "@/mixins/merchant/APIAuth";
import VueRecaptcha from 'vue-recaptcha';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
export default {
  name: 'MerchantRegister',
  components: { VueRecaptcha, VuePhoneNumberInput },
  mixins: [mixinAuth],
  data(){
      return{
        showReCapture: true,
        disable: true,
        mobile: "",
        form: {
          account_type: -1,
          first_name: "",
          middle_name: "",
          last_name: "",
          email:"",
          mobile: "",
          fixed_line_number: "",
          password: "",
          agree: false
        }
      }
  },
  computed: {
    formIsValid() {
      return (
        this.form.account_type &&
        this.form.first_name &&
        this.form.middle_name &&
        this.form.last_name &&
        this.form.mobile &&
        this.form.fixed_line_number &&
        this.form.password &&
        this.form.agree
      );
    }
  },
  methods:{
    verifyEvent(val) {
      if (val !== undefined && val !== "") {
        this.disable = false;
      } else {
        this.disable = true;
      };
    },
    handleLogin(){
      this.$router.push('merchant-login');
    },
    handleContinue: function(obj) {
      this.$router.push({
        name: "Merchant OTP",
        params: { object: obj }
      });
    },
    setPhoneNo(payload){
      this.form.mobile = payload.formattedNumber;
    },
    checkForm: async function (e) {   
      e.preventDefault();
      try {
        let status = await this.merchantRegister(this.form);
          if(status.data._id !== ""){
            this.$swal.fire({
                position: 'top-right',
                type: 'success',
                title: 'Successfully Registered!',
                showConfirmButton: false,
                timer: 1500
              }) 
            this.handleContinue(status.data);
          }      
      } catch (error) {
        this.$swal.fire({
                  position: 'center',
                  type: 'error',
                  title: error.data.message,
                  showConfirmButton: false,
                  timer: 1500
                })
        if(error.data.code === 409){
            // this.handleLogin();
        }        
      }
    }
  }
}
</script>